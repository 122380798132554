.card {
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #d2cccc88;
  margin-bottom: 15px;
}

.image {
  height: 170px;
}

.image img {
  display: block;
  width: 100%;
  height: inherit;
  object-fit: cover;
}

.content {
  padding: 15px 5px;
}

.description {
  font-size: 1rem;
  line-height: 1.4rem;
}

.loading .image,
.loading h4,
.loading p,
.loading .description {
  background-color: #e9e7e770;
  background: linear-gradient(100deg,
      rgba(160, 160, 160, 0) 40%,
      rgba(160, 160, 160, 0.5) 50%,
      rgba(160, 160, 160, 0) 60%) #e9e7e770;
  background-size: 200% 100%;
  background-position-x: 180%;
  /* animation: 1s loading ease-in-out infinite */
}

/* @keyframes loading {
  to {
    background-position-x: -20%
  }
} */

.loading h4 {
  min-height: 1.6rem;
  border-radius: 4px;
  /* animation-delay: 0.05s; */
}

.loading p {
  min-height: 1.6rem;
  border-radius: 4px;
  margin-top: 8px;
  /* animation-delay: 0.05s; */
}

.loading .description {
  min-height: 4rem;
  border-radius: 4px;
  /* animation-delay: 0.06s; */
}